<template>
  <div>
    <div class="search_bar">
      <v-select
          :items="cities"
          v-model="filter.from_location"
          placeholder="Откуда"
          prepend-inner-icon="mdi-map-marker"
          height="48"
          hide-details
          outlined
          dense
          :loading="citiesLoading"
          @change="onChange"
          item-text="name"
          item-value="id"
      ></v-select>
      <v-select
          :items="cities"
          v-model="filter.to_location"
          placeholder="Куда"
          prepend-inner-icon="mdi-map-marker"
          height="48"
          hide-details
          outlined
          dense
          :loading="citiesLoading"
          @change="onChange"
          item-text="name"
          item-value="id"
      ></v-select>
      <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
              v-if="tab==0"
              v-model="filter.from_date"
              placeholder="Дата выезда"
              prepend-inner-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
              hide-details
              outlined
              height="48"
              readonly
              dense
          ></v-text-field>
          <v-text-field
              v-else
              readonly
              v-model="multipleDates"
              placeholder="Дата выезда"
              prepend-inner-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
              hide-details
              outlined
              height="48"
              dense
          ></v-text-field>
        </template>
        <div class="tab_wrapper">
          <v-tabs v-model="tab">
            <v-tab>Только туда</v-tab>
            <v-tab>Туда - обратно</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-date-picker
                  v-model="filter.from_date"
                  no-title
                  :first-day-of-week="1"
                  :allowed-dates="$allowedDates"
                  @input="menu = false"
                  @change="onChange"
              >
              </v-date-picker>
            </v-tab-item>
            <v-tab-item>
              <v-date-picker
                  v-model="filter.dates"
                  no-title
                  range
                  :first-day-of-week="1"
                  :allowed-dates="$allowedDates"
                  multiple
              >
              </v-date-picker>
            </v-tab-item>
          </v-tabs-items>

        </div>
      </v-menu>
      <v-select
          :items="hotels"
          v-model="hotel"
          placeholder="Отель"
          prepend-inner-icon="mdi-map-marker"
          height="48"
          hide-details
          outlined
          dense
          :loading="loadingHotel"
          item-text="name"
          item-value="id"
      ></v-select>

      <people-count ref="peopleCount" :isPensioner='true' :model='filter'/>
      <v-btn @click="find" color="primary" height="48" width="160">
        Найти
      </v-btn>
    </div>
    <near-dates v-if="nearDates.length" :nearDates='nearDates' @onClick='nearClick'/>
    <select-bus-box v-for="item in items" :key="item.id" :item='item' @onClick='onClick'/>
  </div>
</template>

<script>
import peopleCount from '@/components/ui/peopleCount.vue'
import NearDates from '@/components/ui/nearDates.vue'
import SelectBusBox from '@/components/buses/selectBusBox.vue'
import {citiesService} from '@/services/cities.service.js'
import {hotelsService} from '@/services/hotels.service.js'
import {busesService} from '@/services/buses.service.js'

export default {
  components: {
    peopleCount,
    NearDates,
    SelectBusBox
  },
  data: () => ({
    tab: 0,
    nearDates: [],
    menu: false,
    filter: {
      adult: 1,
      child: 0,
      pensioner: 0,
      from_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dates: []
    },
    loadingHotel: false,
    hotel: null,
    hotels: [],
    cities: [],
    citiesLoading: false,
    items: [],
    refresh: 0, 
  }),
  computed: {
    multipleDates() {
      if (this.filter.dates && this.filter.dates.length && this.filter.dates.length == 2) {
        if (this.filter.dates[0] > this.filter.dates[1]) {
          this.filter.dates = this.filter.dates.reverse()
        }
        this.onChange()
      }
      return this.filter.dates ? this.filter.dates.join(' ~ ') : ''
    }
  },
  async created() {
    this.getCities()
    if (Object.keys(this.$route.query).length) {
      this.filter = {}
      let obj = this.$copy(this.$route.query)
      if (this.$route.query.to_date) {
        this.tab = 1
        this.hotel = Number(this.$route.query.hotel)
        this.filter.adult = Number(this.$route.query.adult)
        this.filter.child = Number(this.$route.query.child)
        this.filter.pensioner = Number(this.$route.query.pensioner)
        this.filter.from_date = this.$route.query.from_date
        this.filter.to_date = this.$route.query.to_date
        this.filter.from_location = Number(this.$route.query.from_location)
        this.filter.to_location = Number(this.$route.query.to_location)
        this.filter.dates = [this.$route.query.from_date, this.$route.query.to_date]
      } else {
        this.tab = 0
        this.hotel = Number(this.$route.query.hotel)
        this.filter.adult = Number(this.$route.query.adult)
        this.filter.child = Number(this.$route.query.child)
        this.filter.pensioner = Number(this.$route.query.pensioner)
        this.filter.from_date = this.$route.query.from_date
        this.filter.from_location = Number(obj.from_location)
        this.filter.to_location = Number(obj.to_location)
      }
      console.log(this.hotel);
      await this.getHotels()
      await this.find()
    }
  },
  methods: {
    onChange(e) {
      if(this.refresh > 1){
        this.hotel = null
      }
      this.getHotels()
    },
    isValidSearch() {
      let is_valid = false
      if (this.tab == 0) {
        is_valid = (Number(this.filter.adult) + Number(this.filter.child) + Number(this.filter.pensioner)) > 0 && this.filter.from_location && this.filter.to_location && this.filter.from_date
      } else if (this.tab = 1) {
        is_valid = (Number(this.filter.adult) + Number(this.filter.child) + Number(this.filter.pensioner)) > 0 && this.filter.from_location && this.filter.to_location && this.filter.dates.length > 1
      }
      if (!is_valid) {
        let message = 'Выберите все поля'
        this.$store.commit('ui/SET_NOTIFICATION', {show: true, message, color: 'error'})
      }
      return is_valid
    },
    async getCities() {
      const {results} = await citiesService.getCities()
      this.cities = results
    },
    async getHotels() {
      if (!this.filter.from_location || !this.filter.to_location) return
      this.loadingHotel = true
      let params = {
        from_location: this.filter.from_location,
        to_location: this.filter.to_location,
      }
      if (this.tab == 1) {
        if(this.filter.dates) {
          params.from_date = this.filter.dates[0]
          params.to_date = this.filter.dates[1]
        }
        else return
      } else {
        params.from_date = this.filter.from_date
      }
      this.refresh++
      const {results} = await hotelsService.getHotels(params)
      this.hotels = results
      this.loadingHotel = false
    },
    async find() {
      if (!this.isValidSearch()) return
      try {
        this.$loading(true)
        let filter = {
          is_back: false, 
        }
        if (this.tab == 1) {
          if (this.filter.dates.length) {
            filter.adult = this.filter.adult
            filter.child = this.filter.child
            filter.pensioner = this.filter.pensioner
            filter.from_location = this.filter.from_location
            filter.to_location = this.filter.to_location
            filter.from_date = this.filter.dates[0]
            filter.to_date = this.filter.dates[1]
            filter.hotel = this.hotel
            filter.is_rt = true
          }
        } else {
          filter = {
            adult: this.filter.adult,
            child: this.filter.child,
            pensioner: this.filter.pensioner,
            from_date: this.filter.from_date,
            from_location: this.filter.from_location,
            to_location: this.filter.to_location,
            hotel: this.hotel,
            is_rt: false
          }
        }
        console.log(this.filter, this.hotel);
        this.$router.push({ name: this.$route.name, query: { ...filter } })
        const {results} = await busesService.getActiveBuses(filter)
        this.nearDates = (await busesService.getNearDates(filter)).results
        this.items = results
      } catch (e) {
        console.error(e);
      } finally {
        this.$loading(false)
      }
    },
        nearClick(item) {
            if (this.tab == 1) {
                let diff, aDay = 86400000,start_date = this.filter.dates[0],end_date = this.filter.dates[1];
                diff = Math.floor(
                (
                    Date.parse(
                    end_date.replace(/-/g, '\/')
                ) - Date.parse(
                    start_date.replace(/-/g, '\/')
                )
            ) / aDay);
        this.$set(this.filter.dates, 0, this.$moment(Number(item.near_date) * 1000).format('YYYY-MM-DD'))
        this.$set(this.filter.dates, 1, this.$moment(Number(item.near_date) * 1000).add(diff, 'days').format('YYYY-MM-DD'))
      } else {
        this.filter.from_date = this.$moment(Number(item.near_date) * 1000).format('YYYY-MM-DD')
      } 
      this.find()
    },
    onClick(item) {
      if (this.tab == 0) {
        this.$router.push({
          path: `/booking/buses/ow/${item.id}/`,
          query: {...this.filter, hotel: this.hotel}
        })
      } else {
        let query = {
          adult: this.filter.adult,
          child: this.filter.child,
          pensioner: this.filter.pensioner,
          from_location: this.filter.from_location,
          to_location: this.filter.to_location,
          from_date: this.filter.dates[0],
          to_date: this.filter.dates[1],
          hotel: this.hotel
        }
        this.$router.push({
          path: `/booking/buses/rt/${item.id}/`,
          query
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.search_bar {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 160px;
  align-items: center;
}
</style>